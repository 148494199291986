<template>
  <div class="custom-control custom-radio" style="display:inline-block;margin-right:10px;">
    <label :class="'custom-control-label '+(value==option?'checked':'')">
      <input type="radio" 
      :class="'custom-control-input '+inputClass" :disabled="disabled" :name="name" :value="option" :checked="value==option" :required="required" @click="doChange($event)">
      <slot>{{ label || (option=='Y'?'Active':'Inactive') }}</slot>
    </label>
  </div>
</template>
<script>
let $ = global.jQuery;

export default {
  props:{
    name: String,
    type: String,
    option: [String,Number],
    label: [String,Number],
    value: [String,Number],
    checked: Boolean,
    disabled: Boolean,
    required: {default:false,type:Boolean},
    inputClass:{
      default:'',
      type:String
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    async init(){
      if(this.checked) this.$emit("input", this.option)
      if(this.value==this.option) this.$emit("input", this.option)
    },
    doChange(e){
      this.$emit("input", e.target.value)
    }
  },
  watch:{
    value(v){
      if(v==this.option){
        $(this.$el).find("input").trigger("click")
      }
    }
  }
}
</script>

<style scoped>
.custom-radio, .custom-control-label{
  cursor:pointer
}
.custom-radio .custom-control-label.checked:before {
    color: #fff;
    border-color: #95bd4b;
    background-color: #95bd4b;
}
.custom-radio .custom-control-label.checked:after{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}
</style>